@import "@gumtree/ui-library/src/base/color-variables.scss";
@import "@gumtree/ui-library/src/base/grid.scss";

.gumtree-official {
    .h1 {
        margin-bottom: 0;
        margin-right: 85px;
        padding: 6px 6px $gutter-size--medium 0;
    }

    .icon--circ-check {
        color: $green;
        padding-left: 6px;
        margin-bottom: 5px;
    }

    .related-carousel {
        &.carousel {
            margin: 0 0 $gutter-size--small 0;
        }

        .carousel-card {
            text-align: left;
            user-select: none;
            display: block;
            width: 100%;
            color: $color-main-secondary;
            text-decoration: none;
            box-shadow: 0 0 3px #b7b4b7;
            border-radius: 4px;
            background: white;

            &-image {
                border: 1px solid $color-border--light;
                position: relative;
                height: 200px;
                display: flex;
                justify-content: center;
                overflow: hidden;

                iframe {
                    width: 100%;
                    height: 100%;
                }

                .image {
                    width: 100%;

                    > img {
                        display: block;
                        object-fit: cover;
                    }
                }
            }

            &-text {
                display: flex;
                padding: 6px $gutter-size--medium $gutter-size--medium;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: end;

                .title {
                    padding-top: $gutter-size--small;
                    margin: 0;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 16px;
                    font-weight: 300;
                    text-overflow: ellipsis;
                    width: 200px;

                    @include mq($from: m, $until: xl) {
                        width: 165px;
                    }
                }

                .icon {
                    text-align: right;
                    color: $leaf;
                }
            }
        }

        .carousel-item {
            display: none;
        }

        .carousel-inner {
            padding: 0;
            display: flex;
            height: 250px;
        }

        .carousel-controls-container {
            top: -40px;
            right: 12px;
            display: none;

            .slide-panel {
                background: none;
            }

            @include mq($until: m) {
                display: none;
            }
        }

        .carousel-container {
            max-height: 300px;

            &.loaded {
                .carousel-inner {
                    height: auto;
                }

                .carousel-item {
                    display: flex;
                }

                .carousel-controls-container {
                    display: block;
                }
            }
        }
    }
}
