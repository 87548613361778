@import "@gumtree/ui-library/src/base/grid.scss";
@import "@gumtree/ui-library/src/base/color-variables.scss";

.top-takeover .ad-slot {
    position: relative;
    background-color: $color-background--medium;
    width: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;

    @include mq($from: m) {
        min-height: 20.2vw;
    }
    @include mq($from: l) {
        min-height: 186px;
    }
    @include mq($from: xxl) {
        min-height: 255px;
    }

    div[id^="google_ads_iframe_"] {
        display: flex;
        flex-direction: column;

        iframe:not([width="2000"]) {
            margin: auto;
        }
    }

    iframe[width="2000"] {
        width: 100vw;
        height: 37.5vw;

        @include mq($from: m) {
            height: 20.2vw;
        }

        @include mq($from: l) {
            height: 186px;
        }

        @include mq($from: xxl) {
            height: 255px;
        }
    }

    @include mq($from: m) {
        min-height: 20.2vw;
    }

    @include mq($from: l) {
        min-height: 186px;
    }

    @include mq($from: xxl) {
        min-height: 255px;
    }
}
