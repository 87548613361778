@import "../base/boxes";
@import "../base/colors";

@keyframes fade_collapse {
    0% {
        max-height: none;
        opacity: 1;
        overflow: hidden;
    }

    99% {
        opacity: 0;
    }

    100% {
        display: none;
        overflow: hidden;
        max-height: 0;
        opacity: 0;
    }
}

.user-question {
    margin: auto;
    max-width: 320px;
    text-align: center;

    &.answered {
        animation: fade_collapse 1s forwards;
    }

    &__btn {
        cursor: pointer;
        background: $white;
        border-radius: 100%;
        border: 2px solid $bark;
        display: inline-block;
        height: $box-height;
        margin: 0 0.5em;
        transition: all 0.3s linear;
        width: $box-height;
    }

    .no {
        border-color: $red;

        svg {
            fill: $red;
            transform: rotate(180deg) translateY(-4px);
        }

        &:hover {
            background-color: $red;

            svg {
                fill: $white;
            }
        }
    }

    .yes {
        border-color: $green;

        /* stylelint-disable-next-line no-descending-specificity */
        svg {
            fill: $green;
        }

        &:hover {
            background-color: $green;

            svg {
                fill: $white;
            }
        }
    }
}
