@import "../base/grid.scss";

$max-items-per-row: 6;
$gutter: 12px;

.grid-list {
    margin: -$gutter / 2;

    &-item {
        min-width: 0;
        box-sizing: border-box;
        padding: $gutter / 2;
    }

    @for $items-per-row from 1 to $max-items-per-row {
        &-#{$items-per-row}-items &-item {
            width: 100% / $items-per-row;
        }
    }

    @each $size, $width in $mq-breakpoints {
        @include mq($from: $size) {
            @for $items-per-row from 1 to $max-items-per-row {
                &-#{$items-per-row}-items-#{$size} &-item {
                    width: 100% / $items-per-row;
                }
            }
        }
    }
}
