.aspect-ratio-box {
    position: relative;
}

.aspect-ratio-box-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
