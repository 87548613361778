@import "@gumtree/ui-library/src/base/mixins/visibility.scss";
@import "@gumtree/ui-library/src/base/grid.scss";

$sidebar-width: 168px;
$sidebar-width--large: 300px;
$main-content-min-height: 600px; // in case content is not available this is the right ad banner height

.split-container {
    display: flex;
    min-height: $main-content-min-height;

    > .left {
        flex-grow: 1;
        overflow: hidden;

        @include mq($from: m) {
            padding-right: $gutter-size * 2;
        }
    }

    > .right {
        @include hide-fully-to("m");

        position: relative;
        overflow: hidden;
        min-width: $sidebar-width;
        padding: $gutter-size * 2;

        &:before {
            position: absolute;
            top: 0;
            left: -20px;
            width: 20px;
            height: 300px;
            content: "";
            display: block;
            border-radius: 12px/125px 0;
            box-shadow: 5px 0 20px #d8d6d9;
        }

        @include mq($from: l) {
            min-width: $sidebar-width--large;
        }
    }
}
