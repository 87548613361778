@import "../grid.scss";

@mixin hide-fully-from($breakpoint) {
    @include mq($from: $breakpoint) {
        display: none !important;
    }
}

@mixin hide-fully-to($breakpoint) {
    @include mq($until: $breakpoint) {
        display: none !important;
    }
}

@mixin hide-fully {
    display: none;
}
