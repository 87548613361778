.sticky-container {
    position: relative;
    height: 100%;

    > .content {
        position: relative;

        &.top {
            position: fixed;
            top: 0;
        }

        &.bottom {
            position: absolute;
            bottom: 0;
        }
    }
}
