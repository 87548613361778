@import "../base/grid.scss";

.gt-block {
    background: white;
    padding: $gutter-size--medium $gutter-size;
    margin-left: -$gutter-size;
    margin-right: -$gutter-size;

    .gt-header {
        .header-title {
            margin-bottom: $gutter-size--medium;
        }
    }

    @include mq($from: m) {
        margin-left: 0;
        margin-right: 0;
        padding: $gutter-size--medium;
    }
}
