@import "../base/color-variables.scss";
@import "../base/fonts.scss";
@import "../base/grid.scss";

.related-carousel {
    position: relative;
    margin: $gutter-size--xxlarge 0;

    @include mq($until: l) {
        margin-top: $gutter-size--xlarge;
    }

    .carousel-item {
        padding-left: $gutter-size--large;
    }

    .carousel-inner {
        padding: $gutter-size--medium 0 0 0;
        // negative margin to account for padding left of the carousel item
        margin-left: -$gutter-size--large;
        //expands to account for negative margin-left, hash required to use variable in calc
        width: calc(100% + #{$gutter-size--large});
    }

    .carousel-card {
        text-align: left;
        user-select: none;
        display: block;
        width: 100%;
        color: $color-main-secondary;
        text-decoration: none;

        &-image {
            border: 1px solid $color-border--light;
            position: relative;
            height: 150px;
            display: flex;
            justify-content: center;
            overflow: hidden;

            .image > img {
                display: block;
                object-fit: cover;
            }
        }

        &-text {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-end;

            .title {
                padding-top: $gutter-size--small;
                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                width: 100%;
            }

            .loc-date {
                @include mq($from: m, $until: xl) {
                    width: 100%;
                }
            }

            .date {
                font-size: $font-size--p2;
                padding-top: $gutter-size--small;
                color: $color-text--secondary;
                margin: 0;
            }

            .location {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin: 0;
                width: 100%;
            }
        }

        &-price {
            background: $color-main-tertiary;
            position: absolute;
            right: 0;
            bottom: $gutter-size--medium;
            padding: $gutter-size--small;

            h3 {
                color: $color-font--light;
                margin: 0;
            }
        }
    }

    &-topBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $gutter-size--small;
        margin-right: ($gutter-size--xxlarge * 3);
    }

    &-title {
        margin: 0;

        @include mq($from: l) {
            font-size: $font-size--xlarge;
        }
    }

    &-link {
        color: $color-text--link;
        font-weight: $font-weight--normal;

        @include mq($until: m) {
            display: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .slide-panel {
        color: $color-main-primary;
        font-size: $font-size--xlarge;
        padding: 0;

        @include mq($until: m) {
            background: $white;
        }

        &.next {
            padding: 0 0 0 $gutter-size;
        }

        &.prev {
            margin-right: $gutter-size--medium;
            padding: 0 $gutter-size;
        }

        &.disabled {
            color: rgba(216, 214, 217, 0.8);
        }
    }

    .carousel-controls-container {
        position: absolute;
        top: 0;
        right: 0;

        .slide-panel {
            position: relative;
        }
    }
}
