@import "@gumtree/ui-library/src/base/grid.scss";

/**
 * Concerning the flex items `grid-list-item`:
 * - There is 1 row of items with order 0.
 * - There is (possibly) 1 row of recent items with order 0.
 * - There are 2 rows of items with order 2.
 * - All other items have order 4.
 */

$rows-before-spotlight: 1;

@function items-after-spotlight($items-per-row) {
    @return $rows-before-spotlight * $items-per-row + 1;
}

.user-feed {
    .grid-list-item {
        order: 0;

        article {
            height: 100%;
        }
    }

    @include mq($from: xl) {
        .grid-list-item:nth-child(n + #{items-after-spotlight(4)}) {
            order: 4;
        }
    }

    @include mq($from: l, $until: xl) {
        .grid-list-item:nth-child(n + #{items-after-spotlight(3)}) {
            order: 4;
        }
    }

    @include mq($until: l) {
        .grid-list-item:nth-child(n + #{items-after-spotlight(2)}) {
            order: 4;
        }
    }

    // vrm lookup row.
    > .grid-list > .grid-list-item:nth-last-child(1) {
        order: 1;
        width: 100%;
    }
}
