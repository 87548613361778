@import "@gumtree/ui-library/src/base/color-variables.scss";
@import "@gumtree/ui-library/src/base/grid.scss";

.user-feed-header {
    display: flex;
}

.user-feed-title {
    flex: 2;
}

.user-feed-location {
    padding-top: $gutter-size--small;
    color: $color-text--secondary;
    flex: 1;

    display: flex;
    justify-content: flex-end;
    margin-bottom: $gutter-size--large;
}

.user-feed-location-icon {
    margin-top: $gutter-size--xsmall;
    margin-right: $gutter-size--xsmall;
}
