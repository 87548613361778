@import "../base/color-variables.scss";
@import "../base/boxes.scss";
@import "../base/grid.scss";
@import "../base/fonts.scss";

.listing-tile-thumbnail {
    box-sizing: border-box;

    &-image {
        width: 100%;
        height: 100%;
    }
}
