@import "../base/color-variables.scss";
@import "../base/grid.scss";
@import "../base/z-indexes.scss";
@import "../base/outline.scss";

.carousel {
    &-container {
        width: 100%;
        overflow: hidden;
    }

    &-inner {
        width: 100%;
        display: flex;
        text-align: center;
        padding: 0;
        transition: transform 400ms ease;
    }

    &-item {
        display: flex;
        flex: 1 0 100%;
        box-sizing: border-box;

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    &-link {
        color: $color-main-tertiary;

        &:hover {
            text-decoration: underline;
        }
    }

    &-open {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: $gutter-size--medium;
        right: $gutter-size--large;
        background: $black-overlay;
        padding: $gutter-size--medium $gutter-size--large;
        border-radius: 3px;
        color: $white;
    }
}

.slide-panel {
    position: absolute;
    z-index: $z-index--low;
    background: none;
    border-radius: 3px;
    border: none;
    cursor: pointer;
}
