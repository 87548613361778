@import "../base/color-variables.scss";
@import "../base/grid.scss";

.gt-header {
    .header-location {
        padding-top: $gutter-size--small;
        color: $color-text--secondary;
        flex: 1;
        text-align: right;

        .icon {
            margin-top: -$gutter-size--small;
            margin-right: $gutter-size--xsmall;
        }
    }
}
